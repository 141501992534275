<template>
  <v-dialog
      v-model="dialog"
      max-width="700"
    >
      <v-card :loading="loading">
        <v-card-title class="headline">
          {{ encabezado }} Video
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="formValidity">
            <v-row class="justify-center">
              <v-col cols="12">
                <v-select
									v-model="videoEdit.evento"
									:items="eventos"
                  item-text="slug"
                  item-value="_id"
                  return-object
									label="Eventos"
									dense
									outlined
									:rules="[rules.required]"
                />
                <v-text-field
                  label="Título"
                  v-model="videoEdit.titulo"
                  :rules="[rules.required]"
                />
                <v-text-field
                  label="Autor"
                  v-model="videoEdit.autor"
                  :rules="[rules.required]"
                />
                <v-text-field
                  label="ID Youtube / URL Vimeo"
                  v-model="videoEdit.ref"
                  :rules="[rules.required]"
                />
                <h4>Descripción (Opcional)</h4>
                <tiptap-vuetify
                  v-model="videoEdit.descripcion"
                  :extensions="extensions"
                />
                <v-select
                  v-model="videoEdit.acceso"
                  :items="['Abierto','Premium']"
                  prepend-icon="mdi-focus-field"
                  menu-props="auto"
                  hide-details
                  label="Seleccione Acceso"
                />
                <v-spacer class="mb-5" />
                <v-btn
								x-large
								@click.stop="storeVideo()"
								:loading="saving"
								:disabled="!formValidity"
								rounded
								color="primary accent-3">
								GUARDAR VIDEO
							</v-btn>

              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="closeDialog()"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  import axios from 'axios';
	import store from '@/store'
  const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
  import { TiptapVuetify, HardBreak , Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History } from 'tiptap-vuetify'

  export default {
    name: 'DialogVideo',
    props: {
      dialog: {
        default: false
      },
      mode: {
        default: 'New'
      },
      videoEdit: {
        default: {}
      }
    },
    components: {
      TiptapVuetify,
    },
    data: () => ({
      loading: false,
      saving: false,
			formValidity: false,
			rules: {
				required: value => !!value || 'Required.',
			},
      // video: {
      //   evento: '',
      //   titulo: '',
      //   autor: '',
      //   descripcion: '',
      //   ref: ''
      // },
      eventos: [],
      extensions: [
				History,
				Blockquote,
				Link,
				Underline,
				Strike,
				Italic,
				ListItem,
				BulletList,
				OrderedList,
				Bold,
				HorizontalRule,
				Paragraph,
				HardBreak
			],

    }),
    mounted(){
      this.getEventos()
    },
    methods: {
      async getEventos(){
				let me = this;
				await axios.get('evento/list', configuration)
					.then(
						function (response) {
							me.eventos = response.data;
							me.loading = false
						})
					.catch(function (e) {
						me.loading = false
						console.log(e.message)
					})
			},
      async storeVideo(){
        let me = this
        me.saving = true
        if (me.mode == 'New'){

          await axios.post('video/add', me.videoEdit, configuration)
					.then(function(response){
						me.loading = false
						me.saving = false
						me.$store.commit('SET_ALERT_MESSAGE', {
              show: true,
							color: 'success',
							message: 'Video creado satisfactoriamente',
							timeout: 4500
						}, {root: true})
            me.$emit('getData')
						me.closeDialog()
					})
					.catch(function (e) {
            me.loading = false
            me.saving = false
						me.$store.commit('SET_ALERT_MESSAGE', {
							show: true,
							color: 'red',
							message: 'Lo sentimos, su data no pudo ser procesada! Revise',
							timeout: 4500
						}, {root: true})
					});

        } else {

          this.saving = true
          await axios.put('video/update', {
            _id: me.videoEdit._id,
            evento: me.videoEdit.evento,
            titulo: me.videoEdit.titulo,
            autor: me.videoEdit.autor,
            descripcion: me.videoEdit.descripcion,
            ref: me.videoEdit.ref,
            acceso: me.videoEdit.acceso,
          }, configuration)
            .then(function(response){
              me.$store.commit('SET_ALERT_MESSAGE', {
                show: true,
                color: 'success',
                message: 'Video actualizado satisfactoriamente',
                timeout: 4000
              }, {root: true})
              me.saving = false
            })
            .catch(function (e) {
              me.saving = false
              console.log(e.message)
            })

        }
      },
      closeDialog(){
				this.$refs.form.resetValidation()
        this.$emit('update:dialog', false)
      },
    },
    computed: {
      encabezado(){
        return (this.mode == "New") ? 'Nuevo' : 'Editar';
      }
		}

  }
</script>

<style lang="scss" scoped>

</style>