<template>
  <Layout>
    <v-card class="d-sm-flex align-sm-center justify-sm-space-between mb-6 elevation-0">
      <v-card class="pa-2 text-left elevation-0 order-0 d-flex align-center">
        <h2> <v-icon large>mdi-file-video-outline</v-icon> VIDEOS DIFERIDOS</h2>
        <v-btn fab dark color="primary" class="ml-3"  @click="openDialog">
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-card>
      <v-card class="pa-2 order-2" flat width="400">
        <v-text-field
            class="text-right"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
      </v-card>
    </v-card>
      <v-card>
			  <v-card-text>
          <v-data-table
            :headers="headers"
            :items="videos"
            :search="search"
            item-key="_id"
            class="elevation-1"
            :loading="loading"
            loading-text="Cargando... Espere"
            no-data-text="Sin registros para mostrar"
            :footer-props="{
              'items-per-page-options': [10, 15, 20, 50]
            }"
            :items-per-page="20"
          >
            <template v-slot:item.evento="{ item }">
							{{ item.evento.slug  }}
						</template>

            <!-- columna accion -->
						<template v-slot:item.action="{ item }">
							<v-icon  class="botones_list" color="primary"  @click="editVideo(item)">
								mdi-file
							</v-icon>
						</template>

          </v-data-table>
        </v-card-text>
      </v-card>
      <dialog-video :dialog.sync="dialog" :mode="mode" :videoEdit="video"   />
  </Layout>
</template>

<script>
  import axios from 'axios';
	import store from '@/store'
  import DialogVideo from '@/components/video/DialogVideo.vue'
	import Helpers from '@/services/Helpers.js'
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
  export default {
    name: 'VideoList',
    components: {
      DialogVideo
    },
    data: () => ({
      dialog: false,
      videos: [],
      video: {},
      mode: 'New',
      search: '',
			loading: false,
      headers: [
				{ text: 'TITULO', align: 'start', value: 'titulo'},
				{ text: 'AUTOR', align: 'start', value: 'autor'},
				{ text: 'EVENTO', align: 'start', value: 'evento'},
				{ text: 'ACCESO', align: 'start', value: 'acceso'},
				{ text: '', value: 'action', sortable: false, },
			],
    }),
    mounted(){
      this.getData()
    },
    methods: {
      async getData(){
        let me = this;
				await axios.get('video/list', configuration)
					.then(
						function (response) {
							me.videos = response.data;
							me.loading = false
						})
					.catch(function (e) {
						me.loading = false
						console.log(e.message)
					})
      },
      editVideo(item){
        this.dialog = true
        this.video = item
        this.mode = 'Edit'
      },
      openDialog(mode){
        this.dialog = true
        this.video = {}
        this.mode = 'New'
      },
    },
    computed: {
    }
  }
</script>

<style lang="scss" scoped>
	.botones_list {
		margin: 0px 10px 10px 0px;
			font-size: xx-large;
	}
</style>